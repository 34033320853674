// @flow
import React from 'react'

import TediousManualEntry from 'icons/tedious-manual-entry.svg'
import NoFileStorage from 'icons/no-file-storage.svg'
import HardToTrack from 'icons/hard-to-track.svg'

export default [
	{
		icon: <TediousManualEntry />,
		title: 'Tedious manual entry',
		text:
			'Manually update columns each time you receive bid invites or addenda to track.',
	},
	{
		icon: <NoFileStorage />,
		title: 'No file storage',
		text: 'You have to leave Excel to find the files that you need.',
	},
	{
		icon: <HardToTrack />,
		title: 'Hard to track',
		text:
			'Not easy to group and prioritize, so it’s easy to miss jobs that need bids.',
	},
]
