// @flow
import React from 'react'

import BidForwarding from 'icons/bid-forwarding.svg'
import FileAdobe from 'icons/file-adobe.svg'
import Calendar from 'icons/calendar.svg'
import CloudDownload from 'icons/cloud-download.svg'
import InfoTable from 'icons/info-table.svg'
import AddUser from 'icons/add-user.svg'
import Communication from 'icons/communication.svg'
import MagnifyingGlass from 'icons/magnifying-glass.svg'
import Shield from 'icons/shield.svg'

export default [
	{
		icon: <BidForwarding />,
		title: 'Automatically add everything',
		text:
			'All the details, dates and files are imported right to your bid board.',
	},
	{
		icon: <FileAdobe />,
		title: 'File storage',
		text: 'Files and project details are easily accessed on your bid board.',
	},
	{
		icon: <Calendar />,
		title: 'Calendar sync',
		text:
			'Keep your team on the same page with one shared calendar synced to all your devices.',
	},
	{
		icon: <CloudDownload />,
		title: 'Secure access anywhere',
		text:
			'View the info you need from anywhere, on your secure and private bid board.',
	},
	{
		icon: <InfoTable />,
		title: 'Discover more GCs',
		text:
			'Easily find other GCs bidding on the projects you’ve been invited to bid on.',
	},
	{
		icon: <AddUser />,
		title: 'Assign bids',
		text: 'Use quick notifications to let everyone know who’s working on what.',
	},
	{
		icon: <Communication />,
		title: 'Team communication',
		text:
			'Use your bid board to share notes and important information with your team.',
	},
	{
		icon: <MagnifyingGlass />,
		title: 'Performance and analytics',
		text: 'Bid smarter with GC analytics and team performance tracking.',
	},
	{
		icon: <Shield />,
		title: 'User permissions',
		text:
			'Control who can view and edit specific information with user permissions.',
	},
]
