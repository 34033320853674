// @flow
import * as React from 'react'
import { Div } from 'glamorous'

import OpenMail from './img/open-mail.svg'
import Coffee from './img/coffee.svg'
import Analytics from './img/analytics.svg'
import Trophy from './img/trophy.svg'
import { VerticalInfo } from 'views/components'
import H2 from 'views/components/h2'

const sections = [
	{
		icon: <OpenMail />,
		heading: '1',
		title: 'Forward bid invites',
		wording: `You’re already getting bids from BuildingConnected. Add what’s in your inbox and send to invites@bidboard.buildingconnected.com.`,
	},
	{
		icon: <Coffee />,
		heading: '2',
		title: 'Sip your coffee',
		wording: `Your bid board automatically fills in with details, due dates and addenda.`,
	},
	{
		icon: <Analytics />,
		heading: '3',
		title: 'Track from one secure place',
		wording: `Your bid info is protected and safe from the moment you forward your invites.`,
	},
	{
		icon: (
			<Div marginTop="-25px">
				<Trophy />
			</Div>
		),
		heading: 'It’s win time!',
		wording: `High five your office mates—now you have more time to bring in more wins.`,
	},
]

const Boom = () => (
	<Div>
		<H2 textAlign="center">Boom! Your online bid board just made itself.</H2>
		<Div height="75px" />
		<VerticalInfo sections={sections} />
	</Div>
)

export default Boom
