// @flow
import * as React from 'react'
import { Div, Img } from 'glamorous'

import { InfoAndImage, H2 } from 'views/components'
import { breakpoints } from 'shared/media-queries'
import image from './img/image.png'
import image2x from './img/image@2x.png'
import image3x from './img/image@3x.png'
import { tablet, mobile } from 'shared/media-queries'

const getWordingWidth = screenWidth => {
	if (screenWidth >= breakpoints.desktop) {
		return '486px'
	} else if (screenWidth >= breakpoints.laptop) {
		return '420px'
	}
	return undefined
}

const DangersOfManualBidBoards = ({ screenWidth }: { screenWidth: number }) => (
	<Div>
		<H2 maxWidth="710px" margin="0 auto" textAlign="center">
			The dangers of manual bid boards—email, Excel and whiteboards, oh my!
		</H2>
		<Div
			height="70px"
			css={{
				[tablet]: { height: '50px' },
				[mobile]: { height: '25px' },
			}}
		/>
		<InfoAndImage
			imageOnRight
			image={
				<Img src={image} srcSet={`${image2x} 2x, ${image3x} 3x`} width="100%" />
			}
			wordingWidth={getWordingWidth(screenWidth)}
			spacerWidths={['80px', '60px']}
			titleAndInfoItems={[
				{
					title: 'Total time suck',
					wording: `Drowning in emails, manual entry, addenda, Excel files, contact info, the list goes on...sound familiar?`,
				},
				{
					title: 'Everything’s everywhere',
					wording: `Who’s added what to your bid board? Who’s working on what? Where are all your files? Just asking.`,
				},
				{
					title: 'No track record',
					wording: `It’s tough to get insights on team performance and GCs when you can’t easily access original proposals.`,
				},
				{
					title: 'Mistakes just happen',
					wording: `When you’re manually entering hundreds of bids each month, it’s easy for bids to fall through the cracks.`,
				},
			]}
			centered={screenWidth < breakpoints.tablet}
		/>
	</Div>
)

export default DangersOfManualBidBoards
