// @flow
import * as React from 'react'
import { Div, Img, A } from 'glamorous'

import { ResourceHub } from 'views/components'
import videoImage from './img/video-image.png'
import videoImage2x from './img/video-image@2x.png'
import videoImage3x from './img/video-image@3x.png'
import blogImage from './img/blog-image.png'
import blogImage2x from './img/blog-image@2x.png'
import blogImage3x from './img/blog-image@3x.png'
import { navy, brandBlue } from 'shared/colors'

const cards = [
	{
		image: (
			<Img
				width="100%"
				src={videoImage}
				srcSet={`${videoImage2x} 2x, ${videoImage3x} 3x`}
			/>
		),
		title: 'Video',
		wording: 'How to get started with your online bid board.',
		cta: 'Watch the video',
		videoId: '7i4df8gvgi',
	},
	{
		image: (
			<Img
				width="100%"
				src={blogImage}
				srcSet={`${blogImage2x} 2x, ${blogImage3x} 3x`}
			/>
		),
		title: 'Blog',
		wording: 'The 4 advantages of moving your bid board online.',
		cta: 'Read more',
		href: `https://blog.buildingconnected.com/the-4-advantages-of-moving-your-bid-board-online/`,
	},
	{
		title: 'How To',
		wording: (
			<Div>
				<A
					color={navy}
					href="https://buildingconnected.zendesk.com/hc/en-us/articles/360010226013-How-to-forward-invites-from-your-inbox-to-BuildingConnected-Bid-Board-Pro-"
					target="_blank"
				>
					Forward invites from your inbox.
				</A>
				<br />
				<br />
				<A
					color={navy}
					href="https://buildingconnected.zendesk.com/hc/en-us/articles/360010237513-How-to-manually-add-new-bids-to-your-Bid-Board-"
					target="_blank"
				>
					Manually add new bids to your bid board.
				</A>
				<br />
				<br />

				<A
					color={navy}
					href="https://buildingconnected.zendesk.com/hc/en-us/articles/360012761254"
					target="_blank"
				>
					Sync your calendar with GCal, Outlook or iCal.
				</A>
			</Div>
		),
		cta: (
			<A
				color={brandBlue}
				href="https://buildingconnected.zendesk.com/hc/en-us?solvvy=true"
				target="_blank"
			>
				Go to Help Center
			</A>
		),
	},
]

const ResourceHubSection = () => (
	<ResourceHub
		title="The resource hub."
		subtitle="Everything you need to easily move your bid board online."
		cards={cards}
	/>
)

export default ResourceHubSection
