// @flow
import * as React from 'react'
import { Img } from 'glamorous'

import { breakpoints } from 'shared/media-queries'
import background1610 from './img/1610.png'
import background16102x from './img/1610@2x.png'
import background16103x from './img/1610@3x.png'
import background1440 from './img/1440.png'
import background14402x from './img/1440@2x.png'
import background14403x from './img/1440@3x.png'
import background1024 from './img/1024.png'
import background10242x from './img/1024@2x.png'
import background10243x from './img/1024@3x.png'
import background768 from './img/768.png'
import background7682x from './img/768@2x.png'
import background7683x from './img/768@3x.png'
import background320 from './img/320.png'
import background3202x from './img/320@2x.png'
import background3203x from './img/320@3x.png'

const image1610 = (
	<Img
		src={background1610}
		srcSet={`${background16102x} 2x, ${background16103x} 3x`}
	/>
)
const image1440 = (
	<Img
		src={background1440}
		srcSet={`${background14402x} 2x, ${background14403x} 3x`}
	/>
)
const image1024 = (
	<Img
		src={background1024}
		srcSet={`${background10242x} 2x, ${background10243x} 3x`}
	/>
)
const image768 = (
	<Img
		src={background768}
		srcSet={`${background7682x} 2x, ${background7683x} 3x`}
	/>
)
const image320 = (
	<Img
		src={background320}
		srcSet={`${background3202x} 2x, ${background3203x} 3x`}
	/>
)

const HeaderBackgroundImage = ({ screenWidth }: { screenWidth: number }) => {
	if (screenWidth > breakpoints.desktop) {
		return image1610
	} else if (screenWidth > breakpoints.laptop) {
		return image1440
	} else if (screenWidth > breakpoints.tablet) {
		return image1024
	} else if (screenWidth > breakpoints.mobile) {
		return image768
	} else if (screenWidth > 0) {
		return image320
	}
	return image1610 // Default to largest in case something weird is going on
}

export default HeaderBackgroundImage
