// @flow
import * as React from 'react'
import { Div, Img } from 'glamorous'

import { InfoAndImage, H2 } from 'views/components'
import { breakpoints } from 'shared/media-queries'
import image from './img/image.png'
import image2x from './img/image@2x.png'
import image3x from './img/image@3x.png'
import { tablet, mobile } from 'shared/media-queries'

const getWordingWidth = screenWidth => {
	if (screenWidth >= breakpoints.laptop) {
		return '500px'
	}
	return undefined
}

const BeautyOfManualBidBoards = ({ screenWidth }: { screenWidth: number }) => (
	<Div>
		<H2 textAlign="center" margin="0 auto">
			The beauty of an online bid board—life gets easier.
		</H2>
		<Div
			height="60px"
			css={{
				[tablet]: { height: '50px' },
				[mobile]: { height: '25px' },
			}}
		/>
		<InfoAndImage
			image={
				<Img src={image} srcSet={`${image2x} 2x, ${image3x} 3x`} width="100%" />
			}
			wordingWidth={getWordingWidth(screenWidth)}
			spacerWidths={['120px', '28px']}
			titleAndInfoItems={[
				{
					title: 'Less typing, more winning',
					wording: [
						'Ready in seconds—get time back to focus on wins and growth',
						'Automatically pulls in due dates, client info, files and addenda',
						'No manual entry means fewer mistakes',
					],
				},
				{
					title: 'Mission control for all your bids',
					wording: [
						'Easy-to-access bid info for you and your team, consolidated in one place',
						'Track bids through the entire preconstruction process',
						'See who’s working on what—no misses, no double-work',
					],
				},
				{
					title: 'Numbers that do the legwork for you',
					wording: `Automatically get the insights you need to win more bids, like historical bidding info, analytics and reporting.`,
				},
			]}
			centered={screenWidth < breakpoints.tablet}
		/>
	</Div>
)

export default BeautyOfManualBidBoards
