// @flow
import * as React from 'react'

import Layout from 'components/layout'
import Header from './header'
import {
	SectionSpacer,
	ContentWrapper,
	SectionBreak,
	Footer,
	GrayBackground,
} from 'views/components'
import Dangers from './dangers'
import Beauty from './beauty'
import DitchExcel from './ditch-excel'
import Boom from './boom'
import Quote from './quote'
import ResourceHub from './resource-hub'
import useScreenWidth from 'hooks/use-screen-width'

const MoveBidBoardOnline = () => {
	const screenWidth = useScreenWidth()
	if (!screenWidth) {
		return null
	}

	return (
		<Layout
			title="Move Your Bid Board Online | BuildingConnected"
			description="Save time, eliminate errors, and track all your bids in one secure place using Bid Board Pro."
		>
			<Header screenWidth={screenWidth} />
			<GrayBackground>
				<SectionSpacer />
				<ContentWrapper>
					<Dangers screenWidth={screenWidth} />
				</ContentWrapper>
				<SectionSpacer />
			</GrayBackground>
			<SectionSpacer />
			<ContentWrapper>
				<Beauty screenWidth={screenWidth} />
			</ContentWrapper>
			<SectionSpacer />
			<GrayBackground>
				<SectionSpacer />
				<ContentWrapper>
					<DitchExcel />
				</ContentWrapper>
				<SectionSpacer />
			</GrayBackground>
			<ContentWrapper>
				<SectionSpacer />
				<Boom />
				<SectionBreak />
				<Quote />
				<SectionBreak />
				<ResourceHub />
				<SectionSpacer />
			</ContentWrapper>
			<Footer
				wording="Try Bid Board Pro with your team for free."
				showCreateAccount
				subtext="You’re already getting bids from BuildingConnected, so now’s the time to move your entire bid board online."
				wordingMaxWidth="625px"
			/>
		</Layout>
	)
}

export default MoveBidBoardOnline
