// @flow
import * as React from 'react'
import { Div } from 'glamorous'

import { H2 } from 'views/components'
import QuoteAndImage from 'views/components/carousel/quote-and-image'
import WistiaVideoOverlay from 'views/components/wistia-video-overlay'
import image from './img/image.png'
import image2x from './img/image@2x.png'
import image3x from './img/image@3x.png'
import bradleyLogo from 'imgs/logos/bradley-concrete.png'

const quote = {
	quote: `You forward the invite in one step, it draws all the information automatically...you don’t have to do anything.`,
	name: 'Spencer Harris',
	title: 'Estimator, Project Manager',
	image: {
		src: image,
		srcSet: `${image2x} 2x, ${image3x} 3x`,
	},
	video: {
		length: '1:05',
		element: <WistiaVideoOverlay videoId="hl0l593rw3" />,
	},
	logo: {
		src: bradleyLogo,
		width: '110px',
		height: '20px',
	},
}

const QuoteSection = () => (
	<div>
		<H2 maxWidth="680px" margin="0 auto" textAlign="center">
			See how estimators save 8 hours per week with their online bid board.
		</H2>
		<Div height="50px" />
		<QuoteAndImage {...quote} inlineLogo singleQuote />
	</div>
)

export default QuoteSection
