// @flow
import * as React from 'react'

import excelBad from './excel-bad-sections'
import bidBoardGood from './bid-board-good-sections'
import { IconAndInfoGrid } from 'views/components'

const DitchExcel = () => (
	<IconAndInfoGrid
		sections={[
			{
				title: 'See how Excel falls short in managing bids.',
				content: excelBad,
			},
			{
				title: 'Online bid boards: Built for preconstruction',
				content: bidBoardGood,
			},
		]}
	/>
)

export default DitchExcel
